import { PolicyArComponent } from 'components/policy-ar.component';
import { PolicyEnComponent } from 'components/policy-en.component';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/selectors';
import { AppLocale } from 'store/types';

export default function PolicyPage() {
  const locale = useSelector(localeSelector);

  let component = <PolicyEnComponent />;
  if (locale === AppLocale.AR) {
    component = <PolicyArComponent />;
  }
  return <>{component}</>;
}
