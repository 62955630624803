import ax from 'axios';
import { tokenSelector } from 'store/selectors';
import { Logger } from 'utils';
import { store } from '../App';

const logger = Logger('ApiConstant');

const IS_PROD = process.env.NODE_ENV === 'production';

export const API = {
  baseUrl: IS_PROD ? 'https://api.aqar.homes' : 'http://localhost:4000',
  refreshToken: '/auth/refresh',
  login: '/auth/login',
  profile: '/auth/profile',
  register: '/auth/register',
  resetPassword: '/auth/password/reset',
  aqars: '/aqars',
  unpublished: '/aqars/unpublished',
  aqar: '/aqars/aqar',
  search: '/aqars/search',
  listMyAqars: '/aqars/list-my-aqar',
  updateName: '/users/updateName',
  updatePassword: '/users/updatePassword',
  updateMailListSubscripe: '/users/updateMailListSubscripe',
  addFavAqar: '/users/addFavAqar',
  delFavAqar: '/users/delFavAqar',
  image: (aqarId: string) => `aqars/images/${aqarId}`,
  delImage: (aqarId: string, imgId: string) => `aqars/${aqarId}/image/${imgId}`,
};

const instance = ax.create({
  baseURL: API.baseUrl,
});

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use((config) => {
  if (store && config?.headers) {
    const tokenInfo = tokenSelector(store.getState());
    const token = tokenInfo.token;
    logger('new fetch', config.url);
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return config;
});

export const axios = instance;
