import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from 'constants/aqar';
import { resetAllStateAction, aqarActions } from 'store/actions';
import { NewAqar, Aqar, AqarState, AqarStatus, AqarOwnership, AqarType } from 'store/types';

export const aqarState: AqarState = {
  aqar: {
    address: '',
    state: '',
    city: '',
    lat: 0,
    long: 0,
    totalArea: 0,
    buildArea: 0,
    emptyArea: 0,
    stories: 0,
    bedrooms: 0,
    bathrooms: 0,
    livingrooms: 0,
    hits: 0,
    price: 0,
    info: '',
    currency: Currency.SD_POUND,
    floor: 0,
    aqarNumber: '',
    conatctName: '',
    conatctPhone: '',
    status: AqarStatus.ACTIVE,
    ownership: AqarOwnership.PURE_CERT,
    aqarType: AqarType.HOUSE,
  },
  aqars: [],
  aqarImages: [],
};

export const aqarReducer = createReducer(aqarState, (builder) => {
  builder
    .addCase(resetAllStateAction, (state: AqarState) => {
      state.aqar = { ...aqarState.aqar };
      state.aqars = [];
      state.aqarImages = [];
    })
    .addCase(aqarActions.resetNewAqar, (state: AqarState) => {
      state.aqar = { ...aqarState.aqar };
      state.aqarImages = [];
    })
    .addCase(aqarActions.resetAqar, (state: AqarState) => {
      state.aqar = { ...aqarState.aqar };
      state.aqarImages = [];
    })
    .addCase(aqarActions.updateNewAqar, (state: AqarState, action: PayloadAction<NewAqar>) => {
      state.aqar = action.payload;
    })
    .addCase(aqarActions.updateAqarImages, (state: AqarState, action: PayloadAction<string[]>) => {
      state.aqarImages = action.payload;
    })
    .addCase(aqarActions.updateAqars, (state: AqarState, action: PayloadAction<Aqar[]>) => {
      state.aqars = action.payload;
    });
});
