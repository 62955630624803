import { createAction } from '@reduxjs/toolkit';
import { Aqar } from 'store/types';

const namespace = 'mojo';

export const mojoActions = {
  fetchAqars: createAction(`${namespace}/fetchAqars`),
  publishAqar: createAction<string>(`${namespace}/publishAqar`),
  unPublishAqar: createAction<string>(`${namespace}/unPublishAqar`),
  fetchAqar: createAction<string>(`${namespace}/fetchAqar`),
  updateAqars: createAction<Aqar[]>(`${namespace}/updateAqars`),
};
