import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { ContactHealthCare } from 'components/contact-healthcare.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import babyImg from '../assets/home/baby.jpg';
import babyImg from '../assets/home/resources.jpg';

export default function ResourcesPage() {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1, mt: 5, mb: 10 }} width='100%'>
      <div className='content'>
        <div>
          <div>
            {' '}
            <img src={babyImg} alt='' height={440} width={940} />{' '}
          </div>
          <div id='resources'>
            <div id='aside' className='no-hassle'>
              <span className='first'>{t('content.resources.parent.title')}</span>
              <ul className='section'>
                <li>
                  <Link
                    href='https://www.dhs.pa.gov/Services/Children/Pages/Child-Care-Early-Learning.aspx'
                    underline='always'
                    sx={{ color: '#000' }}
                  >
                    {t('content.resources.parent.p1')}
                  </Link>
                </li>
                <li>
                  <Link href='https://www.cdc.gov/ncbddd/actearly/parents/states.html' underline='always' sx={{ color: '#000' }}>
                    {t('content.resources.parent.p2')}
                  </Link>
                </li>
              </ul>

              <span>{t('content.resources.providers.title')}</span>
              <ul className='section'>
                <li>
                  <Link href='https://jeffline.jefferson.edu/cfsrp/tlc/resources.cfm' underline='always' sx={{ color: '#000' }}>
                    {t('content.resources.providers.p1')}
                  </Link>
                </li>
                <li>
                  <Link href='https://www.pattan.net/' underline='always' sx={{ color: '#000' }}>
                    {t('content.resources.providers.p2')}
                  </Link>
                </li>
                <li>
                  <Link href='https://www.eita-pa.org/coaching-across-settings/' underline='always' sx={{ color: '#000' }}>
                    {t('content.resources.providers.p3')}
                  </Link>
                </li>
                <li>
                  <Link
                    href='https://jeffline.jefferson.edu/cfsrp/tlc/regulations.html'
                    underline='always'
                    sx={{ color: '#000' }}
                  >
                    {t('content.resources.providers.p4')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
