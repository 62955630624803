import { createAction } from '@reduxjs/toolkit';
import { UpdateQuery, FetchQuery } from 'store/types';

const namespace = 'home';

export const homeActions = {
  fetchQuery: createAction<FetchQuery>(`${namespace}/fetchQuery`),
  updateQuery: createAction<UpdateQuery>(`${namespace}/updateQuery`),
  updateLoading: createAction<{ query: string; loading: boolean }>(`${namespace}/updateLoading`),
};
