import { createAction } from '@reduxjs/toolkit';
import { Aqar, Condition, SearchDTO, SearchParamsMatrix, SortOrder } from 'store/types';

const namespace = 'search';

export const searchActions = {
  resetSearch: createAction(`${namespace}/resetSearch`),
  runSearch: createAction(`${namespace}/runSearch`),
  resetSearchUrl: createAction(`${namespace}/resetSearchUrl`),
  decodeSearch: createAction<string>(`${namespace}/decodeSearch`),
  updateQ: createAction<string>(`${namespace}/updateQ`),
  updateQEffect: createAction<string>(`${namespace}/updateQEffect`),
  addFilter: createAction<{ key: string; condition: Condition }>(`${namespace}/addFilter`),
  addFilterEffect: createAction<{ key: string; condition: Condition }>(`${namespace}/addFilterEffect`),
  removeFilter: createAction<string>(`${namespace}/removeFilter`),
  removeFilterEffect: createAction<string>(`${namespace}/removeFilterEffect`),
  updateSort: createAction<{ sortBy: string; sortOrder: SortOrder }>(`${namespace}/updateSort`),
  updateSortEffect: createAction<{ sortBy: string; sortOrder: SortOrder }>(`${namespace}/updateSortEffect`),
  updateSortOrder: createAction<SortOrder>(`${namespace}/updateSortOrder`),
  updateSortOrderEffect: createAction<SortOrder>(`${namespace}/updateSortOrderEffect`),
  updatePage: createAction<number>(`${namespace}/updatePage`),
  updatePageEffect: createAction<number>(`${namespace}/updatePageEffect`),
  updatePerPage: createAction<number>(`${namespace}/updatePerPage`),
  updatePerPageEffect: createAction<number>(`${namespace}/updatePerPageEffect`),
  updateAqarsList: createAction<Aqar[]>(`${namespace}/updateAqarsList`),
  updateSearchMatrix: createAction<SearchParamsMatrix>(`${namespace}/updateSearchMatrix`),
  updateSearchResult: createAction<SearchDTO>(`${namespace}/updateSearchResult`),
};
