import { Aqar } from 'store/types';

export const MAX_TTL = 5 * 60 * 1000;

export interface FetchQuery {
  key: string | number;
  query: string;
}

export interface FeatureAqars {
  aqars: Aqar[];
  lastUpdate: number;
}

export interface UpdateQuery {
  query: string;
  aqars: Aqar[];
}

export type Queries = Record<string, FeatureAqars>;

export interface HomeState {
  queries: Queries;
  loading: Record<string, boolean>;
}
