import { AnyAction } from '@reduxjs/toolkit';
import { API, axios } from 'constants/api';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { homeActions } from 'store/actions';
import { axiosErrorWorker } from 'store/app/app.saga';
import { Queries, SearchDTO, MAX_TTL } from 'store/types';
import { Logger } from 'utils';
import { queriesSelector } from './home.selectors';

const logger = Logger('HomeSaga');

export function* fetchQuery(action: AnyAction) {
  const { query, key } = action.payload;
  try {
    const queries: Queries = yield select(queriesSelector);
    if (key in queries && new Date().getTime() - queries[key].lastUpdate < MAX_TTL) {
      return;
    }
    yield put(homeActions.updateLoading({ query: key, loading: true }));
    const { data }: { data: SearchDTO } = yield call(axios.get, `${API.search}?q=${query}`);
    yield put(homeActions.updateQuery({ query: key, aqars: data.hits.map((dto) => dto.document) }));
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(homeActions.updateLoading({ query: key, loading: false }));
  }
}

export default [takeEvery(homeActions.fetchQuery, fetchQuery)];
