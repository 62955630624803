import { all, AllEffect } from 'redux-saga/effects';
import appSagas from './app/app.saga';
import userSagas from './user/user.saga';
import aqarSagas from './aqar/aqar.saga';
import searchSagas from './search/search.saga';
import homeSagas from './home/home.saga';
import mojoSagas from './mojo/mojo.saga';

export default function* root(): Generator<AllEffect<any>> {
  const sagas: any[] = [...appSagas, ...userSagas, ...aqarSagas, ...searchSagas, ...homeSagas, ...mojoSagas];
  yield all(sagas);
}
