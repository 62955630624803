import { Currency } from 'constants/aqar';
import FormData from 'form-data';

export interface AqarState {
  aqars: Aqar[];
  aqar: NewAqar | Aqar;
  aqarImages: string[];
}

export enum AqarStatus {
  PENDING = 'pending',
  SOLD = 'sold',
  ACTIVE = 'active',
}

export enum AqarType {
  LAND = 'land',
  HOUSE = 'house',
  FLAT = 'flat',
}

export enum AqarOwnership {
  FREE_OWN = 'freeOwn',
  PURE_CERT = 'pureCertificate',
  HEIRS = 'heirs',
  RENT = 'rent',
}

export interface NewAqar {
  id?: string;
  address: string;
  state: string;
  city: string;
  lat: number;
  long: number;
  totalArea: number;
  buildArea: number;
  emptyArea: number;
  stories: number;
  bedrooms: number;
  bathrooms: number;
  livingrooms: number;
  hits: number;
  price: number;
  info: string;
  currency: Currency;
  floor?: number;
  aqarNumber?: string;
  conatctName?: string;
  conatctPhone?: string;
  status: AqarStatus;
  ownership: AqarOwnership;
  aqarType: AqarType;
}

export interface Aqar extends NewAqar {
  id: string;
  userId: string;
  deleted: boolean;
  published: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface AqarListTrans {
  label: string;
  value: any;
  key: string;
}

export interface AqarImage {
  form: FormData;
  aqarId: string;
}

export interface DelAqarImage {
  imgId: string;
  aqarId: string;
}
