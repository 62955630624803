import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactHealthCare } from './contact-healthcare.component';

export default function HomeBannerComponent() {
  const { t } = useTranslation();

  const concernPoints = new Array(3)
    .fill('')
    .map((_, ndx) => <li key={ndx}>{t(`content.earlyEnvironmentService.p${ndx + 1}`)}</li>);

  return (
    <div id='content'>
      <div className='home-banner'>
        <div className='home-banner-content'>
          <h1 style={{ color: '#382d00', fontSize: '20px', fontWeight: 'normal', margin: 0 }}>
            {t('content.earlyEnvironmentService.title')}
          </h1>
          <ul className='section'>{concernPoints}</ul>

          <h2>{t('content.howIsElegible.title')}</h2>
          <p>{t('content.howIsElegible.body')}</p>

          <ContactHealthCare />
        </div>
      </div>
    </div>
  );
}
