import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { resetAllStateAction, mojoActions } from 'store/actions';
import { Aqar, MojState } from 'store/types';

export const mojoState: MojState = {
  aqars: [],
};

export const mojoReducer = createReducer(mojoState, (builder) => {
  builder
    .addCase(resetAllStateAction, (state: MojState) => {
      state.aqars = [];
    })
    .addCase(mojoActions.updateAqars, (state: MojState, action: PayloadAction<Aqar[]>) => {
      state.aqars = action.payload;
    });
});
