import React from 'react';
import Grid from '@mui/material/Grid';
import childlinkLogo from '../assets/home/childlink-logo.png';
import eita from '../assets/home/eita-pa-logo.png';
import dhs from '../assets/home/dhs-keystone-logo.svg';
import dbhids from '../assets/home/dbhid.jpg';
import tlc from '../assets/home/tlc.jpg';
import pfcs from '../assets/home/pfcs-logo.jpg';
import pattan from '../assets/home/pattan-logo.jpg';
import { Trans } from 'react-i18next';

export const OurParteners = () => {
  const itemData = [
    {
      img: dbhids,
      title: 'Philadelphia County Infants Toddlers Early Intervention Program',
    },
    {
      img: dhs,
      title: 'Office of Child Development and Early Learning (OCDEL)',
    },
    {
      img: childlinkLogo,
      title: 'ChildLink',
    },

    {
      img: tlc,
      title: 'Jefferson University Teaching and Learning Collaborative',
    },
    {
      img: pfcs,
      title: 'pfcs',
    },
    {
      img: pattan,
      title: 'pattan',
    },
    {
      img: eita,
      title: 'eita',
    },
  ];

  const imgStyle = { maxWidth: '250px', maxHeight: '150px', height: 'auto', width: 'auto' };

  return (
    <>
      <h2 className='our-partner-h2'>
        <Trans i18nKey='content.ourPartener'>
          OUR <span className='our-partner-span'>PARTNERS</span>
        </Trans>
      </h2>
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        {itemData.map((item) => (
          <Grid item xs={12} sm={6} md={4} sx={{ height: '250px' }}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading='lazy'
              style={imgStyle}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
