import { AnyAction } from '@reduxjs/toolkit';
import { API, axios } from 'constants/api';
import { PAGES } from 'pages';
import { call, put, takeLatest } from 'redux-saga/effects';
import { appActions, userActions } from 'store/actions';
import { loginByPassword, axiosErrorWorker } from 'store/app/app.saga';
import { navigate } from 'store/navigator';
import { UpdatedProfile, UpdateProfileTask, User, UpdateProfileParams } from 'store/types';
import { Logger } from 'utils';

const logger = Logger('UserSaga');

function extractUpdateParams(payload: UpdatedProfile): UpdateProfileParams {
  const { task, profile } = payload;
  switch (task) {
    case UpdateProfileTask.NAME:
      return { url: API.updateName, profile };
    case UpdateProfileTask.PASSWORD:
      return { url: API.updatePassword, profile };
    case UpdateProfileTask.MAIL_LIST:
      return { url: API.updateMailListSubscripe, profile };
  }
}

export function* fetchProfile() {
  try {
    yield put(appActions.showLoading());
    const { data }: { data: User } = yield call(axios.get, API.profile);
    yield put(userActions.updateProfile(data));
  } catch (e) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* updateProfile(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    const { url, profile } = extractUpdateParams(action.payload);
    const { data }: { data: User } = yield call(axios.put, url, profile);
    yield put(userActions.updateProfile(data));
  } catch (e) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* addFavAqar(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    const { data }: { data: User } = yield call(axios.post, API.addFavAqar, { aqarId: action.payload });
    yield put(userActions.updateProfile(data));
  } catch (e) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* delFavAqar(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    const { data }: { data: User } = yield call(axios.post, API.delFavAqar, { aqarId: action.payload });
    yield put(userActions.updateProfile(data));
  } catch (e) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* register(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    const { data }: { data: User } = yield call(axios.post, API.register, action.payload);
    const { username, password } = action.payload;
    yield call(loginByPassword, appActions.loginByPassword({ username, password }));
    yield put(userActions.updateProfile(data));
    yield call(navigate, PAGES.home.href, true);
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export default [
  takeLatest(userActions.fetchProfile, fetchProfile),
  takeLatest(userActions.register, register),
  takeLatest(userActions.updateRemoteProfile, updateProfile),
  takeLatest(userActions.addFavAqar, addFavAqar),
  takeLatest(userActions.delFavAqar, delFavAqar),
];
