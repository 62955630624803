export enum ButtonType {
  TEXT = 'text',
  OUTLINED = 'outlined',
  CONTAINED = 'contained',
}

export interface MenuItem {
  id: string;
  label?: string;
  active?: boolean;
  action?: any;
  Icon?: any;
  Component?: any;
  variant?: ButtonType;
}
