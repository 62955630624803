import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { TOP_NAV_HEIGHT, TOP_NAV_HEIGHT_SMALL_DEVICE } from 'constants/index';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { toPhoneFormat } from 'utils/helpers';
import { MenuItem, ButtonType } from 'constants/menu';
import { navigate } from 'store/navigator';
import { PAGES } from 'pages';
import { useDispatch, useSelector } from 'react-redux';
import { localeSelector } from 'store/selectors';
import { appActions } from 'store/actions';
import { AppLocale } from 'store/types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function HideAppBar({ show }: { show: boolean }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = useSelector(localeSelector);
  const smAndUpper = useMediaQuery(theme.breakpoints.up('sm'));
  const barHeight = smAndUpper ? TOP_NAV_HEIGHT : TOP_NAV_HEIGHT_SMALL_DEVICE;

  const menuItems: MenuItem[] = [
    {
      id: 'conatactUs',
      label: t('header.getStarted'),
      action: () => navigate(PAGES.contactUs.href),
      variant: ButtonType.CONTAINED,
    },
    {
      id: 'english',
      label: t('header.english'),
      action: () => (locale !== AppLocale.EN ? dispatch(appActions.switchLanguage(AppLocale.EN)) : {}),
      variant: ButtonType.TEXT,
      active: locale === AppLocale.EN,
    },
    {
      id: 'spanish',
      label: t('header.spanish'),
      action: () => (locale !== AppLocale.ES ? dispatch(appActions.switchLanguage(AppLocale.ES)) : {}),
      variant: ButtonType.TEXT,
      active: locale === AppLocale.ES,
    },
  ];
  console.log(menuItems);

  return (
    <Slide appear={false} in={show}>
      <AppBar color='secondary' sx={{ height: barHeight }} position='fixed'>
        <Toolbar>
          <Grid container spacing={2} alignItems='center'>
            <Grid xs={12} md={6} item>
              <Grid container justifyContent={smAndUpper ? 'flex-start' : 'center'} alignItems='center'>
                <Button color='primary' startIcon={<PhonelinkRingIcon />} href={`tel:${t('app.phone')}`}>
                  <Typography variant='h6' component='div'>
                    {t('content.callUs.callUs')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent={smAndUpper ? 'flex-end' : 'center'} alignItems='center'>
                {menuItems.map((menu) => (
                  <Button key={menu.id} variant={menu.variant} onClick={menu.action} disabled={menu.active}>
                    {menu.label}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Slide>
  );
}
