import Box from '@mui/material/Box';
import { ContactHealthCare } from 'components/contact-healthcare.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import babyImg from '../assets/home/baby.jpg';
import babyImg from '../assets/imgs/services.jpg';

export default function TherapyPage() {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1, mt: 5, mb: 10 }} width='100%'>
      <div className='content'>
        <div>
          <div>
            {' '}
            <img src={babyImg} alt='' height={440} width={940} />{' '}
          </div>
          <div id='services'>
            <div id='sidebar'>
              {/* <h3>{t('content.callUs.callUs')}</h3> */}
              <ul>
                <li id='vision'>
                  <p>{t('content.callUs.slog')}</p>
                  <p>{t('content.callUs.callUs')}</p>
                </li>
                <li id='mission'>
                  <ContactHealthCare />
                </li>
                {/* <li id='wecare'>
                  {' '}
                  <span>We care</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                    dolore magna aliquam erat volutpat. Ut wisi enim ad minim venia.
                  </p>
                </li> */}
              </ul>
            </div>
            <div id='aside'>
              <p style={{ marginBottom: '20px' }}>{t('content.services.intro')}</p>
              <span className='first'>{t('content.developmentConcern.title')}</span>
              <ul className='section'>
                {new Array(6).fill('').map((_, ndx) => (
                  <li key={ndx}>{t(`content.developmentConcern.p${ndx + 1}`)}</li>
                ))}
              </ul>
              <span>{t('content.therapy.title')}:</span>
              {new Array(4).fill('').map((_, ndx) => (
                <b key={ndx}>~ &nbsp;{t(`content.therapy.p${ndx + 1}`)}</b>
              ))}

              <span>{t('content.approach.title')}</span>
              <ul className='section'>
                <p>{t('content.approach.body1')}</p>
                <p>{t('content.approach.body2')}</p>
              </ul>

              <span>{t('content.therapistRole.title')}</span>
              <ul className='section'>
                <p>{t('content.therapistRole.body1')}</p>
                <p>{t('content.therapistRole.body2')}</p>
                <p>{t('content.therapistRole.body3')}</p>
                <p>{t('content.therapistRole.body4')}</p>
              </ul>

              <span>{t('content.familyRole.title')}</span>
              <ul className='section'>
                <p>{t('content.familyRole.body1')}</p>
                <p>{t('content.familyRole.body2')}</p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
