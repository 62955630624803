import { combineReducers, CombinedState, createAction, AnyAction } from '@reduxjs/toolkit';

import { AppState, UserState, AqarState, SearchState, HomeState, MojState } from './types';

import { appReducer, appInitState } from './app/app.reducer';
import { userReducer, userState } from './user/user.reducer';
import { aqarReducer, aqarState } from './aqar/aqar.reducer';
import { searchReducer, searchState } from './search/search.reducer';
import { homeReducer, homeState } from './home/home.reducer';
import { mojoReducer, mojoState } from './mojo/mojo.reducer';
import { resetAllStateAction } from './actions';

export { appInitState } from './app/app.reducer';
export { userState } from './user/user.reducer';
export { aqarState } from './aqar/aqar.reducer';
export { searchState } from './search/search.reducer';
export { homeState } from './home/home.reducer';
export { mojoState } from './mojo/mojo.reducer';

export type State = CombinedState<{
  app: AppState;
  user: UserState;
  aqar: AqarState;
  search: SearchState;
  home: HomeState;
  mojo: MojState;
}>;

export const initialState: State = {
  app: appInitState,
  user: userState,
  aqar: aqarState,
  search: searchState,
  home: homeState,
  mojo: mojoState,
};

const rootReducer = combineReducers<State, AnyAction>({
  app: appReducer,
  user: userReducer,
  aqar: aqarReducer,
  search: searchReducer,
  home: homeReducer,
  mojo: mojoReducer,
});

export default (state: State = initialState, action: AnyAction) => {
  if (action.type === resetAllStateAction.toString()) {
    // return rootReducer(initialState, action);
  }
  return rootReducer(state, action);
};
