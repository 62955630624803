import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { resetAllStateAction, homeActions } from 'store/actions';
import { UpdateQuery, HomeState } from 'store/types';
import { Logger } from 'utils';

export const homeState: HomeState = {
  queries: {},
  loading: {},
};

const logger = Logger('homeReducer');

export const homeReducer = createReducer(homeState, (builder) => {
  builder
    .addCase(resetAllStateAction, (state: HomeState) => {
      state.queries = {};
      state.loading = {};
    })
    .addCase(homeActions.updateLoading, (state: HomeState, action: PayloadAction<{ query: string; loading: boolean }>) => {
      const { query, loading } = action.payload;
      if (!state.loading) {
        state.loading = {};
      }
      state.loading[query] = loading;
    })
    .addCase(homeActions.updateQuery, (state: HomeState, action: PayloadAction<UpdateQuery>) => {
      const { query, aqars } = action.payload;
      if (!state.queries) {
        state.queries = {};
      }
      state.queries[query] = { aqars, lastUpdate: new Date().getTime() };
    });
});
