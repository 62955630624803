import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { resetAllStateAction, userActions } from 'store/actions';
import { User, UserState } from 'store/types';

export const userState: UserState = {
  profile: undefined,
  favAqars: [],
};

export const userReducer = createReducer(userState, (builder) => {
  builder
    .addCase(userActions.updateProfile, (state: UserState, action: PayloadAction<User>) => {
      state.profile = action.payload;
      if (action.payload.aqars) {
        state.favAqars = action.payload.aqars;
      }
    })
    .addCase(resetAllStateAction, (state: UserState) => {
      state.profile = undefined;
      state.favAqars = [];
    });
});
