import { AnyAction } from '@reduxjs/toolkit';
import { API, axios } from 'constants/api';
import { PAGES } from 'pages';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import i18next from 'i18n';
import { appActions, aqarActions, mojoActions } from 'store/actions';
import { axiosErrorWorker } from 'store/app/app.saga';
import { navigate } from 'store/navigator';
import { NewAqar, Aqar, Alert, AlertType } from 'store/types';
import { Logger } from 'utils';

const logger = Logger('MojoSaga');

export function* fetchAqars() {
  try {
    yield put(appActions.showLoading());
    const { data }: { data: Aqar[] } = yield call(axios.get, API.unpublished);
    yield put(mojoActions.updateAqars(data));
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

// export function* fetchAqar(action: AnyAction) {
//   try {
//     yield put(appActions.showLoading());
//     yield put(aqarActions.resetAqar());
//     const aqarId = action.payload;
//     const { data }: { data: Aqar } = yield call(axios.get, `${API.aqar}/${aqarId}`);
//     yield put(aqarActions.updateNewAqar(data));
//     yield call(fetchAqarImages, action);
//   } catch (e: any) {
//     logger(e);
//     yield call(axiosErrorWorker, e);
//   } finally {
//     yield put(appActions.hideLoading());
//   }
// }

export function* publishAqar(action: AnyAction) {
  const aqarId = action.payload;
  try {
    yield put(appActions.showLoading());
    const url = `${API.aqars}/${aqarId}`;
    yield call(axios.put, url, { published: true });
    yield put(mojoActions.fetchAqars());
    // yield put(appActions.showAlert(new Alert(i18next.t('aqar.create_success'), AlertType.SUCCESS)));
    // TODO: go to photo gallaery
    logger('publish success');
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* unPublishAqar(action: AnyAction) {
  const aqarId = action.payload;
  try {
    yield put(appActions.showLoading());
    const url = `${API.aqars}/${aqarId}`;
    yield call(axios.put, url, { published: false });
    yield put(mojoActions.fetchAqars());
    // yield put(appActions.showAlert(new Alert(i18next.t('aqar.create_success'), AlertType.SUCCESS)));
    // TODO: go to photo gallaery
    logger('publish success');
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export default [
  takeLatest(mojoActions.fetchAqars, fetchAqars),
  // takeLatest(mojoActions.fetchAqar, fetchAqar),
  takeLatest(mojoActions.publishAqar, publishAqar),
  takeLatest(mojoActions.unPublishAqar, unPublishAqar),
];
