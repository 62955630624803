import { createAction } from '@reduxjs/toolkit';
import { Aqar, AqarImage, DelAqarImage, NewAqar } from './aqar.types';

const namespace = 'aqar';

export const aqarActions = {
  updateNewAqar: createAction<NewAqar | Aqar>(`${namespace}/updateNewAqar`),
  resetNewAqar: createAction(`${namespace}/resetNewAqar`),
  resetAqar: createAction(`${namespace}/resetAqar`),
  createNewAqar: createAction(`${namespace}/createNewAqar`),
  updateAqars: createAction<Aqar[]>(`${namespace}/updateAqars`),
  fetchAqars: createAction(`${namespace}/fetchAqars`),
  deleteAqar: createAction<string>(`${namespace}/deleteAqar`),
  fetchAqar: createAction<string>(`${namespace}/fetchAqar`),
  addAqarImage: createAction<AqarImage>(`${namespace}/addAqarImage`),
  delAqarImages: createAction<string>(`${namespace}/delAqarImages`),
  delAqarImage: createAction<DelAqarImage>(`${namespace}/delAqarImage`),
  fetchAqarImages: createAction<string>(`${namespace}/fetchAqarImages`),
  updateAqarImages: createAction<string[]>(`${namespace}/updateAqarImages`),
};
