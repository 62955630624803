import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { arSD, enUS } from '@mui/material/locale';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    aspire: {
      mainLight: string;
      orange: string;
      orangeLight: string;
      blue: string;
    };
  }
  interface PaletteOptions {
    aspire: {
      mainLight: string;
      orange: string;
      orangeLight: string;
      blue: string;
    };
  }
}

// declare module '@mui/material/styles' {
//   interface MojPalette extends Palette {
//     mainLight: string;
//     orange: string;
//     orangeLight: string;
//     blue: string;
//   }
//   interface MyPaletteOptions extends PaletteOptions {
//     mainLight: string;
//     orange: string;
//     orangeLight: string;
//     blue: string;
//   }
// }

// declare module '@mui/material/Button' {
//   interface ButtonPropsColorOverrides {
//     orangeLight: true;
//   }
// }

const themeConfig = {
  palette: {
    primary: {
      main: '#035b1a',
    },
    secondary: {
      main: '#e6e5e5',
    },
    error: {
      main: red.A400,
    },
    aspire: {
      mainLight: '#85c145',
      orange: '#f26230',
      orangeLight: '#ffb546',
      blue: '#406ca9',
    },
  },
};

// A custom theme for this app
export const themeAr = createTheme(
  {
    direction: 'rtl',
    ...themeConfig,
    typography: {
      allVariants: {
        fontFamily: 'Noto Kufi Arabic, libre franklin, ui-sans-serif, sans-serif',
      },
    },
  },
  arSD,
);

export const themeEn = createTheme(
  {
    ...themeConfig,
    typography: {
      allVariants: {
        fontFamily: 'Sansita, ui-sans-serif, sans-serif, Noto Kufi Arabic',
      },
    },
  },
  enUS,
);
