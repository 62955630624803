import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ContactsIcon from '@mui/icons-material/Contacts';
import { MenuItem } from 'constants/menu';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthorizedSelector } from 'store/selectors';
import { useTranslation } from 'react-i18next';
import { navigate } from 'store/navigator';
import { PAGES } from 'pages';
import { Logger } from 'utils';
import DrawerComponent from './drawer.component';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useLocation, matchPath } from 'react-router';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import InfoIcon from '@mui/icons-material/Info';

export default function UserMenuComponent() {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const [activeRoute, setActiveRoute] = useState<any>();
  const { t } = useTranslation();
  const location = useLocation();
  const logger = Logger('UserMenuComponent');

  const theme = useTheme();

  useEffect(() => {
    const currentRoute = Object.values(PAGES).find((page) => matchPath(location.pathname, page.href));
    if (currentRoute) {
      setActiveRoute(currentRoute);
    }
  }, [location.pathname]);
  logger(isAuthorized, location, activeRoute);

  const menuItems: MenuItem[] = [
    {
      id: PAGES.home.href,
      label: t('header.home'),
      action: () => navigate(PAGES.home.href),
      Icon: HomeIcon,
    },
    {
      id: PAGES.therapy.href,
      label: t('header.therapy'),
      action: () => navigate(PAGES.therapy.href),
      Icon: DesignServicesIcon,
    },
    {
      id: PAGES.resources.href,
      label: t('header.resources'),
      action: () => navigate(PAGES.resources.href),
      Icon: InfoIcon,
    },
    {
      id: PAGES.contactUs.href,
      label: t('header.contactUs'),
      action: () => navigate(PAGES.contactUs.href),
      Icon: ContactsIcon,
    },
  ];

  return (
    <>
      <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <DrawerComponent Icon={MenuIcon} items={menuItems} dir='right' label={t('app.name')} hideOnClick />
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        {menuItems.map((menu) => (
          <Button
            key={menu.id}
            onClick={menu.action}
            sx={{
              color: menu.id === activeRoute?.href ? '#000' : '#2e302e',
            }}
          >
            <Typography variant='button' component='h6'>
              {menu.label}
            </Typography>
          </Button>
        ))}
      </Box>
    </>
  );
}
