import { Aqar, AqarOwnership, AqarStatus, AqarType } from 'store/types';

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum FilterOp {
  LIKE = ':',
  EQ = '=',
  GT = '>',
  LT = '<',
  GE = '>=',
  LE = '<=',
  EXACT = ':=',
  BETWEEN = '<>', // array of two
}

export interface Option {
  label: string;
  value: any;
  icon?: any;
}

export interface SearchState {
  aqars: Aqar[];
  resultCount: number;
  q: string;
  filterBy: FilterBy;
  sortBy: string;
  sortOrder: SortOrder;
  page: number;
  perPage: number;
}

export interface Condition {
  key: string;
  op: FilterOp;
  value: string[] | number[];
}

export type FilterBy = Record<string, Condition>;

export interface SearchParamsMatrix {
  q: string;
  filterBy: FilterBy;
  sortBy: string;
  sortOrder: SortOrder;
  page: number;
  perPage: number;
}

export interface FacetEntry {
  count: number;
  value: string;
  highlighted: string;
}

export interface FacetDTO {
  field_name: string;
  counts: FacetEntry[];
}
export interface SearchDTO {
  facet_counts: FacetDTO[];
  found: number;
  hits: { document: Aqar }[];
}

export const Conditions = {
  pureCertificate: (): Condition => ({ key: 'ownership', op: FilterOp.EXACT, value: [AqarOwnership.PURE_CERT] }),
  rents: (): Condition => ({ key: 'ownership', op: FilterOp.EXACT, value: [AqarOwnership.RENT] }),
  activeAqars: (): Condition => ({ key: 'status', op: FilterOp.EXACT, value: [AqarStatus.ACTIVE] }),
  typeLand: (): Condition => ({ key: 'aqarType', op: FilterOp.EXACT, value: [AqarType.LAND] }),
  typeHouse: (): Condition => ({ key: 'aqarType', op: FilterOp.EXACT, value: [AqarType.HOUSE] }),
  typeFlat: (): Condition => ({ key: 'aqarType', op: FilterOp.EXACT, value: [AqarType.FLAT] }),
};
