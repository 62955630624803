import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ContactHealthCare = () => {
  const { t } = useTranslation();
  const liStyle = { height: 'auto', padding: 0, listStyleType: 'circle' };
  return (
    <>
      <p style={{ marginBottom: '10px' }}>
        <b>{t('content.callUs.info')}</b>
      </p>
      <ul>
        <li style={liStyle}>
          <Typography variant='body1' component='div' sx={{ textAlign: 'left' }}>
            {t('content.callUs.p1')}
          </Typography>
        </li>
        <li style={liStyle}>
          <Typography variant='body1' component='div' sx={{ textAlign: 'left' }}>
            {t('content.callUs.p2')}
          </Typography>
        </li>
      </ul>
    </>
  );
};
