import { createAction } from '@reduxjs/toolkit';

export * from './app/app.action';
export * from './user/user.action';
export * from './aqar/aqar.actions';
export * from './search/search.actions';
export * from './home/home.actions';
export * from './mojo/mojo.actions';

export const INIT_ACTION = '@@INIT';
export const resetAllStateAction = createAction('root/resetAllStates');
