import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Logger } from 'utils';
import { navigate } from 'store/navigator';
import { PAGES } from 'pages';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { activeRouteSelector } from 'store/selectors';
import bannerImg from 'assets/imgs/banner.jpg';
import { TOP_NAV_HEIGHT, TOP_NAV_HEIGHT_SMALL_DEVICE } from 'constants/index';
import HideOnScroll from './hide-app-bar.component';
import ElevationScroll from './elevate-app-bar.component';

// const MobileBgImage = 'https://www.kutestkids.com/wp-content/uploads/2022/08/kutest-kids-image.jpg';
// const BgImage = 'https://www.kutestkids.com/wp-content/uploads/2022/08/kutest-kids-image.jpg';
const MobileBgImage = bannerImg;
const BgImage = bannerImg;

const Banner = styled('div')(({ theme }) => ({
  height: `calc(100vh - ${TOP_NAV_HEIGHT_SMALL_DEVICE})`,
  marginTop: TOP_NAV_HEIGHT_SMALL_DEVICE,
  flexGrow: 1,
  backgroundSize: 'cover',
  paddingTop: '20%',
  paddingBottom: 0,
  backgroundPosition: 'center top',
  backgroundImage: `linear-gradient(76deg, rgb(234, 225, 228) 0%, rgba(234, 225, 228, 0.35) 100%), url(${MobileBgImage});`,
  [theme.breakpoints.up('sm')]: {
    height: `calc(100vh - ${TOP_NAV_HEIGHT})`,
    marginTop: TOP_NAV_HEIGHT,
    marginLeft: '-30px',
    marginRight: '-30px',
    backgroundImage: `linear-gradient(76deg, rgb(234, 225, 228) 0%, rgba(234, 225, 228, 0.35) 100%), url(${BgImage});`,
  },
}));

export default function HeaderComponent() {
  const { t } = useTranslation();
  const theme = useTheme();
  const smAndUpper = useMediaQuery(theme.breakpoints.up('sm'));
  const logger = Logger('HeaderComponent');
  const activeRoute: string = useSelector(activeRouteSelector);
  const showSearch = !!PAGES[activeRoute]?.showSearch;
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

  const showTopNav = !trigger;

  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  logger(scrollTop, trigger);

  return (
    <>
      {scrollTop < 200 && <HideOnScroll show={showTopNav} />}
      <ElevationScroll show={trigger} />
      <Box sx={{ flexGrow: 1, mb: '170px' }}>
        {showSearch && (
          <Banner>
            <Grid container>
              <Grid xs={12}>searcg</Grid>
            </Grid>
          </Banner>
        )}
      </Box>
    </>
  );
}
