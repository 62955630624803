import { AnyAction } from '@reduxjs/toolkit';
import { API, axios } from 'constants/api';
import { PAGES } from 'pages';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import i18next from 'i18n';
import { appActions, aqarActions } from 'store/actions';
import { axiosErrorWorker } from 'store/app/app.saga';
import { navigate } from 'store/navigator';
import { NewAqar, Aqar, Alert, AlertType } from 'store/types';
import { Logger } from 'utils';
import { aqarImagesSelector, aqarSelector } from './aqar.selectors';

const logger = Logger('AqarSaga');

export function* createAqar() {
  try {
    yield put(appActions.showLoading());
    const aqar: NewAqar = yield select(aqarSelector);
    const callMethod = aqar.id ? axios.put : axios.post;
    let url = API.aqars;
    if (aqar.id && aqar?.id.length > 0) {
      url += `/${aqar.id}`;
    }
    const { data }: { data: Aqar } = yield call(callMethod, url, aqar);
    yield put(aqarActions.resetNewAqar());
    yield put(appActions.showAlert(new Alert(i18next.t('aqar.create_success'), AlertType.SUCCESS)));
    // TODO: go to photo gallaery
    logger(data.id);
    yield call(navigate, PAGES.myAqar.href, true);
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* fetchMyAqars() {
  try {
    yield put(appActions.showLoading());
    const { data }: { data: Aqar[] } = yield call(axios.get, API.listMyAqars);
    yield put(aqarActions.updateAqars(data));
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* fetchMyAqar(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    yield put(aqarActions.resetAqar());
    const aqarId = action.payload;
    const { data }: { data: Aqar } = yield call(axios.get, `${API.aqar}/${aqarId}`);
    yield put(aqarActions.updateNewAqar(data));
    yield call(fetchAqarImages, action);
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* addAqarImage(action: AnyAction) {
  const { form, aqarId } = action.payload;
  try {
    yield put(appActions.showLoading());
    const { data }: { data: { ref: string } } = yield call(axios.post, API.image(aqarId), form);
    const aqarImages: string[] = yield select(aqarImagesSelector);
    const clonedImages = aqarImages.slice(0);
    clonedImages.push(data.ref);
    yield put(aqarActions.updateAqarImages(clonedImages));
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* fetchAqarImages(action: AnyAction) {
  const aqarId = action.payload;
  try {
    yield put(appActions.showLoading());
    const { data }: { data: string[] } = yield call(axios.get, API.image(aqarId));
    yield put(aqarActions.updateAqarImages(data));
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* delAqarImages(action: AnyAction) {
  const aqarId = action.payload;
  try {
    yield put(appActions.showLoading());
    yield call(axios.delete, API.image(aqarId));
    yield put(aqarActions.updateAqarImages([]));
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* delImage(action: AnyAction) {
  const { aqarId, imgId } = action.payload;
  try {
    yield put(appActions.showLoading());
    yield call(axios.delete, API.delImage(aqarId, imgId));
    const aqarImages: string[] = yield select(aqarImagesSelector);
    yield put(aqarActions.updateAqarImages(aqarImages.filter((id) => id !== imgId)));
  } catch (e: any) {
    logger(e);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export default [
  takeLatest(aqarActions.createNewAqar, createAqar),
  takeLatest(aqarActions.fetchAqars, fetchMyAqars),
  takeLatest(aqarActions.fetchAqar, fetchMyAqar),
  takeLatest(aqarActions.fetchAqarImages, fetchAqarImages),
  takeLatest(aqarActions.addAqarImage, addAqarImage),
  takeLatest(aqarActions.delAqarImage, delImage),
  takeLatest(aqarActions.delAqarImages, delAqarImages),
];
