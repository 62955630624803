import { createAction } from '@reduxjs/toolkit';
import { AppLocale, TokenDto, LoginDto, AlertProps } from './app.type';

const namespace = 'app';

export const appActions = {
  initializeTheApp: createAction(`${namespace}/initializeTheApp`),
  setAppReady: createAction<boolean>(`${namespace}/setAppReady`),
  showLoading: createAction(`${namespace}/showLoading`),
  hideLoading: createAction(`${namespace}/hideLoading`),
  setToken: createAction<TokenDto>(`${namespace}/setToken`),
  setRedirectUrl: createAction<string>(`${namespace}/setRedirectUrl`),
  switchLanguage: createAction<AppLocale>(`${namespace}/switchLanguage`),
  updateLocale: createAction<AppLocale>(`${namespace}/updateLocale`),
  loginByToken: createAction(`${namespace}/loginByToken`),
  loginByPassword: createAction<LoginDto>(`${namespace}/loginByPassword`),
  authorize: createAction(`${namespace}/authorize`),
  resetToken: createAction(`${namespace}/resetToken`),
  showAlert: createAction<AlertProps>(`${namespace}/showAlert`),
  hideAlert: createAction<string>(`${namespace}/hideAlert`),
  resetPassword: createAction<string>(`${namespace}/resetPassword`),
  logout: createAction(`${namespace}/logout`),
  toggleActiveRoute: createAction<string>(`${namespace}/toggleActiveRoute`),
  updateActiveRoute: createAction<string>(`${namespace}/updateActiveRoute`),
};
