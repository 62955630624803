import i18next from 'i18n';
import * as CryptoJS from 'crypto-js';
import { Condition, SearchParamsMatrix, SortOrder } from 'store/types';

export function getFnName(d: any) {
  const error: any = new Error();
  const firefoxMatch = (error.stack.split('\n')[0 + d]?.match(/^.*(?=@)/) || [])[0];
  const chromeMatch = ((((error.stack.split('at ') || [])[1 + d] || '')?.match(/(^|\.| <| )(.*[^(<])( \()/) || [])[2] || '')
    .split('.')
    .pop();
  const safariMatch = error.stack.split('\n')[0 + d];

  // firefoxMatch ? console.log('firefoxMatch', firefoxMatch) : void 0;
  // chromeMatch ? console.log('chromeMatch', chromeMatch) : void 0;
  // safariMatch ? console.log('safariMatch', safariMatch) : void 0;

  return firefoxMatch || chromeMatch || safariMatch;
}

export const Logger =
  (name: string) =>
  (...vals: any[]) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`%c Aqar:${name}:`, 'color: #bada55', ...vals);
    }
  };

export const isValidEmail = (emailValue: string): [boolean, string] => {
  const { t } = i18next;
  let valid = true;
  let error = '';

  if (!emailValue) {
    valid = false;
    error = t('login.required_field');
  }
  // eslint-disable-next-line no-useless-escape
  else if (!/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(emailValue)) {
    valid = false;
    error = t('login.invalid_value');
  }
  return [valid, error];
};

export function encodeSearch(searchMatrix: SearchParamsMatrix): string {
  const txt = JSON.stringify(searchMatrix);
  const digest = CryptoJS.AES.encrypt(txt, 'q');
  return encodeURIComponent(digest.toString());
}

export function decodeSearch(queryString: string): SearchParamsMatrix {
  const query = decodeURIComponent(queryString);
  const decrypted = CryptoJS.AES.decrypt(query, 'q');
  const obj = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)) as SearchParamsMatrix;
  return obj;
}

export function conditionsToSearchParamsMetrix(conditions: Condition[], sortBy: string, limit = 5): SearchParamsMatrix {
  const res: SearchParamsMatrix = {
    q: '*',
    filterBy: {},
    sortOrder: SortOrder.DESC,
    sortBy,
    page: 1,
    perPage: limit,
  };

  conditions.forEach((cond) => {
    res.filterBy[cond.key] = cond;
  });

  return res;
}

export function toPhoneFormat(txt: string) {
  return `${txt.slice(0, 3)}-${txt.slice(3, 6)}-${txt.slice(6)}`;
}
