import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import { Logger } from 'utils';
import ListSubheader from '@mui/material/ListSubheader';
import { MenuItem } from 'constants/menu';
import Typography from '@mui/material/Typography';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface MenuProps {
  handleClose?: () => void;
  Icon?: any;
  dir: Anchor;
  items: MenuItem[];
  label?: string;
  width?: number;
  btnLbl?: boolean;
  hideOnClick?: boolean;
}

export default function DrawerComponent(props: MenuProps) {
  const { handleClose, Icon, items, dir, label, width, btnLbl, hideOnClick } = props;
  const { t } = useTranslation();
  const logger = Logger('DrawerComponent');
  const [open, setOpen] = React.useState(false);
  const anchor = dir;
  logger(anchor, hideOnClick);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    logger(hideOnClick);
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
    if (typeof handleClose === 'function' && !open) {
      handleClose();
    }
  };

  const list = () => (
    <Box sx={{ width: width || 250 }} role='presentation' onClick={(e) => hideOnClick && toggleDrawer(false)(e)}>
      <Toolbar variant='dense'>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='menu'
          sx={{ mr: 2 }}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant='h6' color='inherit' component='div'>
          {label}
        </Typography>
      </Toolbar>
      <Divider />
      <List sx={{ bgcolor: 'background.paper' }} component='nav'>
        {items.map((item, ndx) => {
          const body = item.Component ? (
            <item.Component />
          ) : (
            <ListItemButton>
              {item.Icon && (
                <ListItemIcon>
                  <item.Icon />
                </ListItemIcon>
              )}
              <ListItemText primary={item.label} />
            </ListItemButton>
          );
          return (
            <React.Fragment key={item.id}>
              <ListItem onClick={() => item.action && item.action()} disablePadding>
                {body}
              </ListItem>
              {ndx < items.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );

  const MyButton =
    label && btnLbl ? (
      <Button variant='outlined' size='small' startIcon={<Icon />} onClick={toggleDrawer(true)}>
        {label}
      </Button>
    ) : (
      <IconButton
        onClick={toggleDrawer(true)}
        size='large'
        edge='start'
        color='inherit'
        aria-label='open drawer'
        sx={{ mr: 0, color: '#000' }}
      >
        <Icon />
      </IconButton>
    );

  return (
    <div id={label}>
      {MyButton}
      <SwipeableDrawer anchor={anchor} open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
        {list()}
      </SwipeableDrawer>
    </div>
  );
}
