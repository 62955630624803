import Box from '@mui/material/Box';
import HomeBannerComponent from 'components/home-banner.component';
import { OurParteners } from 'components/our-partener.component';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { homeActions } from 'store/actions';
import { loadingsSelector, queriesSelector } from 'store/selectors';
import { Condition, Conditions } from 'store/types';

export default function HomePage() {
  const { t } = useTranslation();
  const loadings = useSelector(loadingsSelector);
  const queries = useSelector(queriesSelector);
  const dispatch = useDispatch();

  const labels: string[] = [];

  // AqarListComponent
  return (
    <Box sx={{ flexGrow: 1, mt: 5 }} width='100%'>
      <HomeBannerComponent />

      <OurParteners />
    </Box>
  );
}
