import { createSelector } from '@reduxjs/toolkit';
import { State, searchState } from 'store/reducers';
import { SearchParamsMatrix } from './search.types';

export const searchStateSelector = (state: State) => state?.search || searchState;

export const aqarsListSelector = createSelector(searchStateSelector, (state) => state.aqars);

export const qSelector = createSelector(searchStateSelector, (state) => state.q);

export const resultCountSelector = createSelector(searchStateSelector, (state) => state.resultCount);

export const filterBySelector = createSelector(searchStateSelector, (state) => state.filterBy);

export const ownershipSelector = createSelector(filterBySelector, (state) => state?.ownership);

export const sortBySelector = createSelector(searchStateSelector, (state) => state.sortBy);

export const sortOrderSelector = createSelector(searchStateSelector, (state) => state.sortOrder);

export const pageSelector = createSelector(searchStateSelector, (state) => state.page);

export const perPageSelector = createSelector(searchStateSelector, (state) => state.perPage);

export const searchMatrixSelector = createSelector(
  [qSelector, filterBySelector, sortBySelector, sortOrderSelector, pageSelector, perPageSelector],
  (q, filterBy, sortBy, sortOrder, page, perPage): SearchParamsMatrix => ({ q, filterBy, sortBy, sortOrder, page, perPage }),
);
