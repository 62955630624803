import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

function Q({ txt, q }: { txt: string; q?: boolean }) {
  const { t } = useTranslation();
  const aqarName = t('app.name');
  return (
    <Typography variant={q ? 'h6' : 'body1'} gutterBottom textAlign='start' sx={{ m: '16px', p: '16px' }}>
      {txt.split('Aqar').join(aqarName)}
    </Typography>
  );
}

export function PolicyArComponent() {
  return (
    <>
      <Q q txt='ماذا يفعل Aqar بالمعلومات التي أقدمها؟' />
      <Q
        txt=' قد نطلب منك تزويدنا بمعلومات الاتصال والمعلومات الديموغرافية عند التسجيل. نستخدم هذه المعلومات لإرسال معلومات لمستخدمي
        موقعنا ، مثلك ، عن موقعنا أو خدماتنا. نستخدم هذه المعلومات أيضًا للاتصال بك عبر الإنترنت وغير متصل عند الحاجة.'
      />

      <Q q txt='ماذا يفعل Aqar بعنوان IP الخاص بي وملفات تعريف الارتباط؟' />
      <Q txt='نحن نستخدم عنوان IP الخاص بك للمساعدة في تشخيص المشاكل مع خادمنا وإدارة موقعنا. يتم استخدام عنوان IP الخاص بك لجمع معلومات ديموغرافية واسعة ويتم تخزينه كمعرف جزئي. نحن نستخدم ملفات تعريف الارتباط ، عند الاقتضاء ، لتقديم محتوى خاص باهتماماتك وعند الاقتضاء لحفظ كلمة مرورك حتى لا تضطر إلى إعادة إدخالها في كل مرة تزور فيها موقعنا. يمكنك ضبط المتصفح الخاص بك لرفض ملفات تعريف الارتباط. إذا قمت بهذا الاختيار ، فقد لا تكون مؤهلاً لأي عروض خاصة أو عروض ترويجية قد نقدمها لمستخدمي موقعنا.' />

      <Q q txt='هل يتم مشاركة معلوماتي الشخصية؟' />
      <Q txt='لا.' />

      <Q q txt='هل ستتغير سياسة الخصوصية هذه؟' />
      <Q txt='نعم ، قد نقوم بتعديل سياسة الخصوصية هذه من وقت لآخر.' />

      <Q q txt='أسئلة؟ اتصل بنا.' />
      <Q txt='إذا كانت لديك أي أسئلة حول بيان الخصوصية هذا ، أو ممارسات هذا الموقع ، أو استخدام علاماتنا التجارية والمواد المحمية بحقوق الطبع والنشر أو لأي مخاوف أخرى ، فلا تتردد في الاتصال بنا.' />

      <Q q txt='' />
      <Q txt='' />

      <Q q txt='' />
      <Q txt='' />

      <Q q txt='' />
      <Q txt='' />
    </>
  );
}

export function UsageArComponent() {
  return (
    <>
      <Q q txt='اعلانات العقارات' />
      <Q txt='المحتوى الموجود على موقع Aqar نابع من المستخدمين والأعضاء المسجلين في Aqar، وبالتالي لا يتحمل Aqar اي مسؤولية عن المحتوى المُضاف ويتحمل المستخدمين كافة المسؤولية. يقوم Aqar بدور رقابي على الإعلانات والمحتوى المُضاف من قبل المستخدمين بطريقتين، قبل الإضافة (ولا تشمل جميع الإعلانات) حيث ان بعض الإعلانات لا تظهر على الموقع إلا بموافقة إدارة الموقع، والنوع الثاني، بعد الإضافة، حيث تظهر الإعلانات على الموقع ومن ثم يتم مراقبتها وتصحييها إن لزم الأمر.' />

      <Q q txt='تحذير!' />
      <Q
        txt='Aqar ليست مسؤولة عن أي قرارات استثمارية من قبل القراء أو المشتركين. يوصى بشدة بمناقشة أي قرار شراء أو بيع مع مستشار مالي أو وسيط-تاجر أو عضو في أي هيئات تنظيمية مالية.

تم توفير المعلومات الواردة هنا كخدمة معلومات فقط. لا يوجد ما يبرر دقة أو اكتمال المعلومات وهي موثوقة فقط مثل المصادر التي تم الحصول عليها منها. يتم تحذير المستثمرين من أنهم قد يخسرون كل أو جزء من استثماراتهم عندما لا يتوخون الحيطة والحذر عند التعامل مع الاعلانات في هذا الموقع.'
      />

      <Q q txt='نصائح التعامل الآمن' />
      <Q txt='لا ترسل أي مبالغ مالية' />
      <Q txt='لا تكمل اي اجراءات بيع او شراء او ايجار عقار خارج السلطة القضائية' />
      <Q txt='عين محاميا موثوق لتكملة اجراءاتك' />
      <Q txt='' />
      <Q txt='' />
    </>
  );
}

export function AboutUs() {
  return (
    <>
      <Q q txt='' />
      <Q txt='موقع عقار يقدم خدمة نشر وتسهيل الاعلانات العقارية حتى يسهل التواصل بين العملاء. يقوم موقع عقار بنشر بيانات العقار متضمنة للموقع الجغرافي للعقار بالإضافة لعدد من البيانات التفصيلية الأخرى التي تسهل من عملية ايجاد العقار وتسويقه بكل يسر وسهولة.' />
    </>
  );
}
