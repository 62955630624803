import { createAction } from '@reduxjs/toolkit';
import { Aqar } from 'store/types';
import { User, UpdatedProfile } from './user.types';

const namespace = 'user';

export const userActions = {
  register: createAction<Partial<User>>(`${namespace}/register`),
  fetchProfile: createAction(`${namespace}/fetchProfile`),
  updateProfile: createAction<User>(`${namespace}/updateProfile`),
  updateRemoteProfile: createAction<UpdatedProfile>(`${namespace}/updateRemoteProfile`),
  updateName: createAction<string>(`${namespace}/updateName`),
  addFavAqar: createAction<string>(`${namespace}/addFavAqar`),
  delFavAqar: createAction<string>(`${namespace}/delFavAqar`),
  updateFavAqars: createAction<Aqar[]>(`${namespace}/updateFavAqars`),
};
