import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import FooterComponent from 'components/footer.component';
import HeaderComponent from 'components/header.component';
import { useDispatch, useSelector } from 'react-redux';
import { alertsSelector, isLoadingSelector } from 'store/selectors';
import Stack from '@mui/material/Stack';
import { appActions } from 'store/actions';
import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const StyledBox = styled(Box);

export default function MainPage({ children }: any) {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const alerts = useSelector(alertsSelector);
  const headerRef = useRef<any>();

  const hideAlert = (alertId: string) => dispatch(appActions.hideAlert(alertId));

  const alersList = alerts.map((alert) => (
    <Alert severity={alert.type} key={alert.id} onClose={() => hideAlert(alert.id)}>
      {alert.message}
    </Alert>
  ));

  useEffect(() => {
    console.log(headerRef.current, headerRef.current?.clientHeight);
  }, [headerRef]);

  return (
    <Container sx={{ maxWidth: '100vw', minWidth: '100vw' }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid container minWidth='xs' direction='column' justifyContent='center' alignItems='center'>
        <Grid item width='100%'>
          <div ref={headerRef as any} style={{ height: 'auto' }}>
            <HeaderComponent />
            <Stack sx={{ width: '100%' }} spacing={2}>
              {alersList}
            </Stack>
          </div>
        </Grid>
        <Grid item width='100%'>
          {children}
        </Grid>
        <Grid item width='100%'>
          <FooterComponent />
        </Grid>
      </Grid>
    </Container>
  );
}
