import { AnyAction } from '@reduxjs/toolkit';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import { appActions, searchActions } from 'store/actions';
import { SearchParamsMatrix, SearchDTO } from 'store/types';
import { searchMatrixSelector } from 'store/selectors';
import { API, axios } from 'constants/api';
import { Logger, encodeSearch, decodeSearch } from 'utils';
import { axiosErrorWorker } from 'store/app/app.saga';

const logger = Logger('SearchSaga');

export function* runSearch() {
  try {
    yield put(appActions.showLoading());
    const searchmatrix: SearchParamsMatrix = yield select(searchMatrixSelector);
    const query = encodeSearch(searchmatrix);
    logger('searchmatrix', query);
    const url = new URL(window.location as any);
    url.searchParams.set('q', query);
    window.history.pushState(null, '', url.toString());
    const { data }: { data: SearchDTO } = yield call(axios.get, `${API.search}?q=${query}`);
    yield put(searchActions.updateSearchResult(data));
  } catch (e) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* decodeSearchWorker(action: AnyAction) {
  try {
    const query = action.payload;
    yield put(searchActions.resetSearch());
    const searchMatrix: SearchParamsMatrix = decodeSearch(query);
    logger('searchmatrix', searchMatrix);
    yield put(searchActions.updateSearchMatrix(searchMatrix));
    yield put(searchActions.runSearch());
  } catch (e) {
    yield call(axiosErrorWorker, e);
  }
}

export function* updateQEffect(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    yield put(searchActions.updateQ(action.payload));
    yield call(runSearch);
  } catch (e: any) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* addFilter(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    yield put(searchActions.updateQ(''));
    yield put(searchActions.addFilter(action.payload));
    yield call(runSearch);
  } catch (e: any) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* removeFilter(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    yield put(searchActions.updateQ(''));
    yield put(searchActions.removeFilter(action.payload));
    yield call(runSearch);
  } catch (e: any) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* updateSort(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    yield put(searchActions.updateQ(''));
    yield put(searchActions.updateSort(action.payload));
    yield call(runSearch);
  } catch (e: any) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* updatePage(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    yield put(searchActions.updateQ(''));
    yield put(searchActions.updatePage(action.payload));
    yield call(runSearch);
  } catch (e: any) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* updatePerPage(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    yield put(searchActions.updateQ(''));
    yield put(searchActions.updatePage(1));
    yield put(searchActions.updatePerPage(action.payload));
    yield call(runSearch);
  } catch (e: any) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* resetSearchUrl() {
  const url = new URL(window.location as any);
  yield call(url.searchParams.delete, 'q');
  yield call(window.history.pushState, null, '', url.toString());
}

export default [
  takeLatest(searchActions.runSearch, runSearch),
  takeLatest(searchActions.decodeSearch, decodeSearchWorker),
  takeLatest(searchActions.updateQEffect, updateQEffect),
  takeLatest(searchActions.addFilterEffect, addFilter),
  takeLatest(searchActions.removeFilterEffect, removeFilter),
  takeLatest(searchActions.updateSortEffect, updateSort),
  takeLatest(searchActions.updatePageEffect, updatePage),
  takeLatest(searchActions.updatePerPageEffect, updatePerPage),
  takeLatest(searchActions.resetSearchUrl, resetSearchUrl),
];
