import { Aqar } from '../aqar/aqar.types';

export enum UserStatus {
  ACTIVE = 'Active',
  REGESTRED = 'Registered',
  DELETED = 'Deleted',
}

export enum UserType {
  AGENT = 'agent',
  CUSTOMER = 'customer',
}

export interface User {
  id: string;
  userType: UserType;
  username: string;
  password: string;
  name?: string;
  activationCode?: string;
  refreshToken?: string;
  lastlogin: Date;
  userStatus: UserStatus;
  isSocialMedia: boolean;
  tel?: string;
  credit: number;
  pushToken?: string;
  phoneConfirmed: boolean;
  mailListSubscripe: boolean;
  createdAt: Date;
  updatedAt: Date;
  rank: number;
  aqars: Aqar[];
}

export interface UserState {
  profile: User | undefined;
  favAqars: Aqar[];
}

// export type UpdatedProfile = Partial<User>;

export enum UpdateProfileTask {
  NAME = 'name',
  PASSWORD = 'password',
  MAIL_LIST = 'mailList',
}

export interface UpdatedProfile {
  task: UpdateProfileTask;
  profile: Partial<User>;
}

export interface UpdateProfileParams {
  url: string;
  profile: Partial<User>;
}
