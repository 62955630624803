import { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Page } from 'constants/index';
import { setNavigation, isNavigatorReady } from 'store/navigator';
import AuthComponent from 'components/auth.component';
import HomePage from './home.page';
import LoginPage from './login.page';
import RegisterPage from './register.page';
import ErrorPage from './error.page';
import Loading from 'components/loading.component';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'store/actions';
import { isAppReadySelector } from 'store/selectors';
import ThemeComponent from 'components/theme.component';
import { Logger } from 'utils';
import MainPage from './main.page';
import ResetPasswordPage from './reset-password';
import PolicyPage from './policy.page';
import UsagePage from './usage.page';
import AboutUsPage from './about-us.page';
import TherapyPage from './therpay.page';
import ResourcesPage from './resources.page';

export const PAGES: Record<string, Page> = {
  home: {
    name: 'home',
    href: '/',
    component: HomePage,
    showSearch: false,
    protected: false,
  },
  therapy: {
    name: 'therapy',
    href: '/therapy',
    component: TherapyPage,
    showSearch: false,
    protected: false,
  },
  resources: {
    name: 'resources',
    href: '/resources',
    component: ResourcesPage,
    showSearch: false,
    protected: false,
  },
  policy: {
    name: 'policy',
    href: '/policy',
    component: PolicyPage,
  },
  usage: {
    name: 'usage',
    href: '/usage',
    component: UsagePage,
  },
  contactUs: {
    name: 'conatctUs',
    href: '/contact',
    component: AboutUsPage,
  },
  error: {
    name: 'error',
    href: '/error',
    component: ErrorPage,
  },
  any: {
    name: 'any',
    href: '*',
    component: ErrorPage,
  },
};

function PageWrapper({ route, children }: { route: string; children: any }) {
  const logger = Logger('PageWrapper');
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    logger('app mount', route);
    dispatch(appActions.toggleActiveRoute(route));
  }, [location.pathname]);

  return <>{children}</>;
}

export function Pages() {
  const logger = Logger('PageComponent');
  const navigator = useNavigate();
  const [isReady, setIsReady] = useState<boolean>(false);
  const isAppReady = useSelector(isAppReadySelector);

  useEffect(() => {
    setNavigation(navigator);
    setIsReady(true);
  }, []);

  if (isNavigatorReady() && !isReady) {
    setIsReady(true);
  }

  logger(isAppReady, isReady, isNavigatorReady());
  if (!isReady || !isAppReady) {
    return <Loading />;
  }

  const routes = Object.values(PAGES).map((page) => {
    let element = (
      <PageWrapper route={page.name}>
        <page.component />
      </PageWrapper>
    );
    if (page.protected) {
      element = (
        <PageWrapper route={page.name}>
          <AuthComponent>
            <page.component />
          </AuthComponent>
        </PageWrapper>
      );
    }
    return <Route key={page.href} path={page.href} element={element} />;
  });
  return <Routes>{routes}</Routes>;
}

export default function PagesWrapper() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.initializeTheApp());
  }, []);

  return (
    <ThemeComponent>
      <MainPage>
        <Pages />
      </MainPage>
    </ThemeComponent>
  );
}
