import { v4 as uuidv4 } from 'uuid';

export enum AppLocale {
  AR = 'ar',
  EN = 'en',
  ES = 'es',
}

export interface AppState {
  isAppReady: boolean;
  loading: number;
  tokenInfo: TokenDto;
  redirectUrl: string;
  locale: AppLocale;
  alerts: AlertProps[];
  activeRoute: string;
}

export interface LoginDto {
  username: string;
  password: string;
}

export interface TokenDto {
  token: string;
  userId: string;
  refreshToken?: string;
  active: boolean;
}

export interface RefreshTokenDto {
  refreshToken: string;
  userId: string;
}

export enum AlertType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface AlertProps {
  id: string;
  message: string;
  type: AlertType;
}

export class Alert implements AlertProps {
  id: string;
  message: string;
  type: AlertType;
  constructor(msg: string, type = AlertType.ERROR) {
    this.message = msg;
    this.type = type;
    this.id = uuidv4();
  }
}
