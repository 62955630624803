import { Store, configureStore, applyMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer, { State, initialState } from './reducers';
import rootSaga from './sagas';

const persistConfig = {
  key: 'root',
  storage,
};

export default () => {
  const middleware: any[] = [];
  const enhancers: any[] = [];
  const sagaMiddleware = createSagaMiddleware();

  middleware.push(sagaMiddleware);

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  enhancers.push(applyMiddleware(...middleware));
  const store: Store<State> = configureStore({
    reducer: persistedReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    enhancers,
    devTools: process.env?.NODE_ENV !== 'production',
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};
