import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { appActions } from 'store/actions';
import { isAuthorizedSelector, tokenSelector } from 'store/selectors';
import { navigate } from 'store/navigator';
import { PAGES } from 'pages';

export default function AuthComponent({ children }: any) {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const token = useSelector(tokenSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  console.log('moj: Auth component', token, isAuthorized);

  if (!isAuthorized) {
    const { pathname, search } = location;
    const path = pathname + search;
    dispatch(appActions.setRedirectUrl(path));
    navigate(PAGES.login.href, true);
    // return <LoginPage />;
  }
  return <>{children}</>;
}
