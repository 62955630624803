export const stages = [['address', 'state', 'location'], ['']];

export enum Currency {
  SD_POUND = 'sdPound',
  US_DOLLAR = 'usDollar',
}

export const SD_POUND = '£';
export const US_DOLLAR = '$';

export const imagePath =
  process.env.NODE_ENV === 'production' ? 'https://img.melbakre.com/aqars' : 'http://192.168.1.207:3000/aqars';

export function getImage(aqarId: string, imgId: string): string {
  return `${imagePath}/${aqarId}/${imgId}`;
}
