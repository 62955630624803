import { AppBar, Button, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { PAGES } from 'pages';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'store/navigator';
import { MenuItem } from 'constants/menu';

export default function FooterComponent() {
  const { t } = useTranslation();
  const theme = useTheme();

  const menuItems: MenuItem[] = [
    {
      id: 'home',
      label: t('header.home'),
      action: () => navigate(PAGES.home.href),
    },
    {
      id: 'theraby',
      label: t('header.therapy'),
      action: () => navigate(PAGES.therapy.href),
    },
    {
      id: PAGES.resources.href,
      label: t('header.resources'),
      action: () => navigate(PAGES.resources.href),
    },
    {
      id: 'policy',
      label: t('footer.policy'),
      action: () => navigate(PAGES.policy.href),
    },
    {
      id: 'conatctUs',
      label: t('footer.contactUs'),
      action: () => navigate(PAGES.contactUs.href),
    },
  ];

  const footerMenu = menuItems.map((menu) => (
    <Grid key={menu.id} item>
      <Button variant='text' onClick={() => menu.action()} color='secondary'>
        {menu.label}
      </Button>
    </Grid>
  ));

  return (
    <AppBar position='fixed' color='primary' sx={{ top: 'auto', bottom: 0, height: '100px' }}>
      <Toolbar>
        <Grid container width='100%' spacing={2} alignItems='center'>
          <Grid item container xs={12} sx={{ mt: 2 }} direction='row' justifyContent='space-evenly' alignItems='center'>
            {footerMenu}
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' color='secondary' align='center'>
              {`© ${new Date().getFullYear()} ${t('app.name')} ${t('footer.rights')}.`}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
