import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { resetAllStateAction, searchActions } from 'store/actions';
import {
  Aqar,
  SearchState,
  SearchParamsMatrix,
  SortOrder,
  Condition,
  SearchDTO,
  AqarStatus,
  FilterBy,
  FilterOp,
  AqarOwnership,
} from 'store/types';
import { Logger } from 'utils';

function defaultFilterBy(): FilterBy {
  return {
    status: {
      value: [AqarStatus.ACTIVE],
      op: FilterOp.EXACT,
      key: 'status',
    },
    ownership: {
      value: [AqarOwnership.PURE_CERT],
      op: FilterOp.EXACT,
      key: 'ownership',
    },
  };
}

const rawFilter: SearchParamsMatrix = {
  q: '',
  filterBy: defaultFilterBy(),
  sortBy: '',
  sortOrder: SortOrder.ASC,
  page: 1,
  perPage: 10,
};

export const searchState: SearchState = {
  aqars: [],
  resultCount: 0,
  ...rawFilter,
};

const logger = Logger('searchReducer');

export const searchReducer = createReducer(searchState, (builder) => {
  builder
    .addCase(resetAllStateAction, (state: SearchState) => {
      state.aqars = [];
      state.resultCount = 0;
      state.q = rawFilter.q;
      state.filterBy = defaultFilterBy();
      state.sortBy = '';
      state.sortOrder = SortOrder.ASC;
      state.page = 1;
      state.perPage = 10;
    })
    .addCase(searchActions.resetSearch, (state: SearchState) => {
      state.aqars = [];
      state.resultCount = 0;
      state.q = rawFilter.q;
      state.filterBy = defaultFilterBy();
      state.sortBy = '';
      state.sortOrder = SortOrder.ASC;
      state.page = 1;
      state.perPage = 10;
      const url = new URL(window.location as any);
      url.searchParams.delete('q');
      window.history.pushState(null, '', url.toString());
      logger('reset url');
    })
    .addCase(searchActions.updateSearchMatrix, (state: SearchState, action: PayloadAction<SearchParamsMatrix>) => {
      const { q, filterBy, sortBy, sortOrder, page, perPage } = action.payload;
      state.q = q;
      state.filterBy = filterBy;
      state.sortBy = sortBy;
      state.sortOrder = sortOrder;
      state.page = page;
      state.perPage = perPage;
    })
    .addCase(searchActions.updateQ, (state: SearchState, action: PayloadAction<string>) => {
      state.q = action.payload;
    })
    .addCase(searchActions.updateSort, (state: SearchState, action: PayloadAction<{ sortBy: string; sortOrder: SortOrder }>) => {
      state.sortBy = action.payload.sortBy;
      state.sortOrder = action.payload.sortOrder;
    })
    .addCase(searchActions.addFilter, (state: SearchState, action: PayloadAction<{ key: string; condition: Condition }>) => {
      const { key, condition } = action.payload;
      state.filterBy[key] = condition;
    })
    .addCase(searchActions.removeFilter, (state: SearchState, action: PayloadAction<string>) => {
      delete state.filterBy[action.payload];
    })
    .addCase(searchActions.updatePage, (state: SearchState, action: PayloadAction<number>) => {
      state.page = action.payload;
    })
    .addCase(searchActions.updatePerPage, (state: SearchState, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    })
    .addCase(searchActions.updateSearchResult, (state: SearchState, action: PayloadAction<SearchDTO>) => {
      state.resultCount = action.payload.found;
      state.aqars = action.payload.hits.map((dto) => dto.document);
    })
    .addCase(searchActions.updateAqarsList, (state: SearchState, action: PayloadAction<Aqar[]>) => {
      state.aqars = action.payload;
    });
});
