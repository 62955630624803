import React, { useCallback, useState } from 'react';
import { Container, Grid, InputAdornment, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CallIcon from '@mui/icons-material/Call';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import { toPhoneFormat } from 'utils/helpers';

export default function AboutUsPage() {
  const { t } = useTranslation();
  const infoEmail = 'info@aspiretherapypa.com';
  const [data, setData] = useState({ fname: '', lname: '', phone: '', email: '', body: '' });

  const reset = () => setData({ fname: '', lname: '', phone: '', email: '', body: '' });

  const updateEntry = (key: string, val: string) => setData({ ...data, [key]: val });

  const disable = !data.fname || !data.lname || !data.email || !data.phone || !data.body;

  const getBody = useCallback(
    () => `
  First name: ${data.fname},
  Last name: ${data.lname},
  Email: ${data.email},
  Phone: ${data.phone},
  Body: ${data.body}
  `,
    [data],
  );

  const getSubject = useCallback(() => `Contact from ${data.fname} ${data.lname}`, [data]);

  return (
    <Container sx={{ mb: 20 }}>
      <h3>{t('contactUs.title')}</h3>
      <h1>{t('contactUs.subTitle')}</h1>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <p>{t('contactUs.addressDesc')}</p>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemAvatar>
                    <CallIcon color='primary' />
                  </ListItemAvatar>
                  <ListItemText primary={t('contactUs.callUs')} secondary={toPhoneFormat(t('app.phone'))} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <AlternateEmailIcon color='primary' />
                  </ListItemAvatar>
                  <ListItemText primary={t('contactUs.email')} secondary={t('app.email')} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <LocationOnIcon color='primary' />
                  </ListItemAvatar>
                  <ListItemText primary={t('contactUs.address')} secondary={t('app.address')} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <p>{t('contactUs.formDesc')}</p>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Grid container>
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder={t('contactUs.fname')}
                  onChange={(e) => updateEntry('fname', e.target.value)}
                  value={data.fname}
                />
                <Box sx={{ m: 2 }} />
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder={t('contactUs.lname')}
                  onChange={(e) => updateEntry('lname', e.target.value)}
                  value={data.lname}
                />
                <Box sx={{ m: 2 }} />
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder={t('contactUs.email')}
                  onChange={(e) => updateEntry('email', e.target.value)}
                  value={data.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AlternateEmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ m: 2 }} />
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder={t('contactUs.phone')}
                  onChange={(e) => updateEntry('phone', e.target.value)}
                  value={data.phone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <CallIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ m: 2 }} />
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder={t('contactUs.message')}
                  value={data.body}
                  multiline
                  rows={4}
                  onChange={(e) => updateEntry('body', e.target.value)}
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Button onClick={reset}>{t('contactUs.reset')}</Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button disabled={disable} href={`mailto:${infoEmail}?subject=${getSubject()}&body=${getBody()}`}>
                {t('contactUs.submit')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
