import { createSelector } from '@reduxjs/toolkit';
import { State, appInitState } from 'store/reducers';

export const appStateSelector = (state: State) => state?.app || appInitState;

export const isAppReadySelector = createSelector(appStateSelector, (state) => state.isAppReady);

export const isAuthorizedSelector = createSelector(
  appStateSelector,
  (state) => state.tokenInfo.token && state.tokenInfo.token.length > 0,
);

export const tokenSelector = createSelector(appStateSelector, (state) => state.tokenInfo);

export const activeRouteSelector = createSelector(appStateSelector, (state) => state.activeRoute);

export const localeSelector = createSelector(appStateSelector, (state) => state.locale);

export const isLoadingSelector = createSelector(appStateSelector, (state) => state.loading > 0);

export const alertsSelector = createSelector(appStateSelector, (state) => state.alerts);

export const redirectUrlSelector = createSelector(appStateSelector, (state) => state.redirectUrl);
