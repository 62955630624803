import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { TOP_NAV_HEIGHT, TOP_NAV_HEIGHT_SMALL_DEVICE } from 'constants/index';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import logo from 'assets/imgs/header-logo.png';
import { navigate } from 'store/navigator';
import { PAGES } from 'pages';

import UserMenuComponent from './user-menu.component';

interface Props {
  children: React.ReactElement;
  show: boolean;
}

function ElevationScroll(props: Props) {
  const { children, show } = props;
  return React.cloneElement(children, {
    elevation: show ? 4 : 0,
  });
}

export default function ElevateAppBar({ show }: { show: boolean }) {
  const theme = useTheme();
  const smAndUpper = useMediaQuery(theme.breakpoints.up('sm'));
  const imgHeight = smAndUpper ? '70px' : '50px';
  const barHeight = smAndUpper ? TOP_NAV_HEIGHT : TOP_NAV_HEIGHT_SMALL_DEVICE;
  const toHome = () => navigate(PAGES.home.href);

  return (
    <ElevationScroll show={show}>
      {/* <AppBar sx={{ top: show ? 0 : TOP_NAV_HEIGHT, backgroundColor: show ? theme.palette.primary.main : 'transparent' }}> */}
      <AppBar sx={{ top: show ? 0 : barHeight, backgroundColor: show ? '#fff' : 'transparent' }}>
        <Toolbar>
          <IconButton onClick={() => toHome()} size='large' edge='start' color='inherit' aria-label='open drawer'>
            <img src={logo} height={imgHeight} />
          </IconButton>
          <UserMenuComponent />
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
