import { createSelector } from '@reduxjs/toolkit';
import { State, aqarState } from 'store/reducers';

export const aqarStateSelector = (state: State) => state?.aqar || aqarState;

export const aqarSelector = createSelector(aqarStateSelector, (state) => state.aqar);

export const aqarImagesSelector = createSelector(aqarStateSelector, (state) => state.aqarImages);

export const aqarsSelector = createSelector(aqarStateSelector, (state) => state.aqars);
