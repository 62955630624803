import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { axios } from 'constants/api';
import { AlertProps, AppLocale, AppState, TokenDto } from './app.type';
import { resetAllStateAction, appActions } from 'store/actions';

export const appInitState: AppState = {
  isAppReady: false,
  loading: 0,
  tokenInfo: {
    token: '',
    refreshToken: '',
    userId: '',
    active: false,
  },
  redirectUrl: '',
  locale: AppLocale.EN,
  alerts: [],
  activeRoute: 'home',
};

export const appReducer = createReducer(appInitState, (builder) => {
  builder
    .addCase(resetAllStateAction, (state: AppState) => {
      state.loading = 0;
      state.tokenInfo = {
        token: '',
        refreshToken: '',
        userId: '',
        active: false,
      };
      state.redirectUrl = '';
      state.alerts = [];
    })
    .addCase(appActions.setAppReady, (state: AppState, action: PayloadAction<boolean>) => {
      state.isAppReady = action.payload;
    })
    .addCase(appActions.updateActiveRoute, (state: AppState, action: PayloadAction<string>) => {
      state.activeRoute = action.payload;
    })
    .addCase(appActions.setToken, (state: AppState, action: PayloadAction<TokenDto>) => {
      state.tokenInfo.token = action.payload.token;
      state.tokenInfo.refreshToken = action.payload.refreshToken;
      state.tokenInfo.active = action.payload.active;
      state.tokenInfo.userId = action.payload.userId;
      axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`;
    })
    .addCase(appActions.resetToken, (state: AppState) => {
      state.tokenInfo.token = '';
      state.tokenInfo.refreshToken = '';
      state.tokenInfo.active = false;
      state.tokenInfo.userId = '';
      axios.defaults.headers.common['Authorization'] = '';
    })
    .addCase(appActions.updateLocale, (state: AppState, action: PayloadAction<AppLocale>) => {
      state.locale = action.payload;
    })
    .addCase(appActions.showLoading, (state: AppState) => {
      state.loading += 1;
    })
    .addCase(appActions.hideLoading, (state: AppState) => {
      state.loading -= 1;
    })
    .addCase(appActions.showAlert, (state: AppState, action: PayloadAction<AlertProps>) => {
      state.alerts.push(action.payload);
    })
    .addCase(appActions.hideAlert, (state: AppState, action: PayloadAction<string>) => {
      state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
    })
    .addCase(appActions.setRedirectUrl, (state: AppState, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    });
});
